import React, { useEffect, useRef, memo } from 'react';
import Search from "./filters/Search"
import Results from './results/Results';
import LoadingSpinner from './LoadingSpinner';
import { useState } from 'react';
import Pagination from './Pagination';
import Fuse from "fuse.js";
import { Button, Container, Grid } from '@mui/material';

const Filter = () => {

    const [posts, setPosts] = useState([]);
    const [refPosts, setRefPosts] = useState([]);
    const [loadingPosts, setLoadingPosts] = useState(false);
    const [showScrollToTopButton, setShowScrollToTopButton] = useState(false);
    const [numberOfResultsShowing, setNumberOfResultsShowing] = useState(10)

    const resetPostHandler = (postsLoading) => {

        if (postsLoading) {
            setPostsLoading(postsLoading)
            setRefPosts(posts)
        } else {
            setPostsLoading(postsLoading)
        }
    }

    useEffect(() => {
        document.addEventListener('scroll', (e) => {
            const elem = document.getElementById('main-results-displayed-container');

            const totalOffSet = Number((elem.getBoundingClientRect().top + window.scrollY).toFixed(0));
            const distanceFromTopScrolled = Number((elem.getBoundingClientRect().top).toFixed(0));


            if (distanceFromTopScrolled <= (totalOffSet - 600) && showScrollToTopButton === false) {
                setShowScrollToTopButton(true);
            }

            if (distanceFromTopScrolled === totalOffSet) {
                setShowScrollToTopButton(false);
            }

        })
    }, [])

    const ftsOptions = {
        isCaseSensitive: true,
        includeScore: true,
        includeMatches: true,
        minMatchCharLength: 3,
        findAllMatches: true, // When true, the matching function will continue to the end of a search pattern even if a perfect match has already been located in the string
        // sortFn: (a, b) => a.score < b.score,
        shouldSort: true,
        threshold: 0.5,
        useExtendedSearch: true,
        keys: ['title.rendered', 'acf.authors', 'acf.source'],
    }

    const fuse = new Fuse(refPosts, ftsOptions);

    const setFetchedPostData = (data) => {
        setPosts(data);
        setRefPosts(data);
    }

    const setPostsLoading = (boolVal) => {
        setLoadingPosts(boolVal)
    }

    const validResultSortingHandler = (obj, element) => {

        // Building the searchQuery
        let validResult = true;
        const { year, authors, forest_type, species_type, climate_zone, aspect } = element.acf;

        // We check one queryObject property at a time
        for (const k in obj) {
            if (Object.hasOwnProperty.call(obj, k)) {
                const el = obj[k]; // startYear, endYear
                const key = k;

                if (key === "startYear") {
                    let postStartDate = Number(year); // startYear of the post || element.acf.

                    if (!el || Number(el) > postStartDate) {
                        validResult = false;
                        break;                           // exits the loop early
                    }
                }

                if (key === "endYear") {
                    let postEndDate = Number(year);

                    if (!el || Number(el) < postEndDate) {
                        validResult = false;
                        break;                         // exits the loop early
                    }
                }

                if (key === "forest_type") {
                    const stringifiedForestType = forest_type.length > 0 ? forest_type.join() : forest_type

                    // check if each post's forest_type is includes in the stringified version of object passed as a search parameter
                    if (el.length > 0) {
                        el.every(e => {
                            if (!stringifiedForestType.includes(e)) {
                                validResult = false
                                return false;            // exits the loop early
                            }
                        })
                    } else {
                        break                           // exits the loop early
                    }


                    if (!validResult) {
                        break            // exits the loop early
                    }
                }

                if (key === "species_type") {

                    const stringifiedSpeciesType = species_type.length > 0 ? species_type.join() : species_type

                    // check if each post's forest_type is includes in the stringified version of object passed as a search parameter
                    if (el.length > 0) {
                        el.every(e => {
                            if (!stringifiedSpeciesType.includes(e)) {
                                validResult = false
                                return false;            // exits the loop early
                            }
                        })
                    } else {
                        break            // exits the loop early
                    }

                    if (!validResult) {
                        break            // exits the loop early
                    }
                }

                if (key === "climate_zone") {
                    const stringifiedClimateZone = climate_zone.length > 0 ? climate_zone.join() : climate_zone

                    // check if each post's forest_type is includes in the stringified version of object passed as a search parameter
                    if (el.length > 0) {
                        el.every(e => {
                            if (!stringifiedClimateZone.includes(e)) {
                                validResult = false
                                return false;            // exits the loop early
                            }
                        })
                    } else {
                        break            // exits the loop early
                    }


                    if (!validResult) {
                        break            // exits the loop early
                    }
                }

                if (key === "aspect") {
                    const stringifiedAspect = aspect.length > 0 ? aspect.join() : aspect

                    // check if each post's forest_type is includes in the stringified version of object passed as a search parameter
                    if (el.length > 0) {
                        el.every(e => {
                            if (!stringifiedAspect.includes(e)) {
                                validResult = false
                                return false;            // exits the loop early
                            }
                        })
                    } else {
                        break            // exits the loop early
                    }


                    if (!validResult) {
                        break            // exits the loop early
                    }
                }

                if (key === "authors") {
                    if (el !== authors) {
                        validResult = false
                        break            // exits the loop early
                    }
                }
            }
        }

        return validResult;
    }

    const filterResult = (queryObject) => {

        setLoadingPosts(true);
        // paginationRef.current.resetPaginationHandler();
        // paginationRef.current.value = 1;

        setTimeout(() => {

            setLoadingPosts(false);

            let validResults = [];
            let referenceArrayOfPostItems = [...posts];

            const { keyword } = queryObject;

            // Performing full text search
            if (keyword) {
                // Setting the reference posts to be the return value from fullTextSearch
                referenceArrayOfPostItems = fullTextSearch(keyword);
            }

            // Getting the selected options from the query
            let queryFilteredQuery = {}
            for (const key in queryObject) {
                if (Object.hasOwnProperty.call(queryObject, key)) {
                    const identifier = key;
                    const queryObjectVal = queryObject[identifier];

                    if (queryObjectVal && (queryObjectVal.length > 0 || queryObjectVal > 0)) {
                        queryFilteredQuery[identifier] = queryObjectVal
                    }
                }
            }

            // Getting the keys of the object that has values. -> { "startYear": 2000, "endYear": 2005, "climate_zone": [ "Equatorial" ] }
            const queryObjectLength = Object.keys(queryFilteredQuery).length;

            if (queryObjectLength > 0) {
                for (let i = 0; i < referenceArrayOfPostItems.length; i++) {
                    const element = referenceArrayOfPostItems[i];
                    if (validResultSortingHandler(queryFilteredQuery, element)) {
                        validResults.push(element)
                    }
                }
            } else {
                validResults = posts;
            }

            referenceArrayOfPostItems = validResults

            setRefPosts(referenceArrayOfPostItems)

        }, 300)

    }

    const fullTextSearch = (value) => {
        const ftsResults = fuse.search(value);
        let refArray = [];

        if (ftsResults.length > 0) {
            ftsResults.forEach(res => {
                refArray.push(res.item);
            });
            // setRefPosts(refArray);
        } else {
            setRefPosts([]);
        }

        return refArray;
    }

    const scrollTopTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    const incrementResultCount = () => {
        if (numberOfResultsShowing > refPosts.length) {
            return
        } else {
            setNumberOfResultsShowing((prevState) => prevState += 10)
            // console.log("incrmenet", numberOfResultsShowing);
            let showingRefPostsArray = [];

            for (let index = 0; index < numberOfResultsShowing; index++) {
                if (index < refPosts.length) {
                    const element = refPosts[index];
                    showingRefPostsArray.push(element)
                }
            }

            // console.log("showingRefPostsArray", numberOfResultsShowing, showingRefPostsArray);
        }
    }

    return (
        <React.Fragment>
            {/* <img className='banner-image' src="https://fwpa.com.au/wp-content/uploads/2022/07/Banner-04.jpg" /> */}
            <Container fixed>
                <div className="banner-container">
                    <h1>Publications Search Filter</h1>
                </div>

                <Grid container item={true}>
                    <Grid container style={{
                        width: "100%",
                        margin: "0 auto",
                        paddingTop: "2%",
                        background: "#fcfbfd"
                    }} item={true}>
                        <Search
                            fetchingForParent={setFetchedPostData} // setting posts for parent element
                            filterSearchTerm={filterResult}  // filterinng function
                            loading={setPostsLoading}
                            resetPosts={resetPostHandler}
                            propRefPosts={refPosts}
                        ></Search>
                    </Grid>
                </Grid>

                <div id='main-results-displayed-container'>


                    <Grid container style={{ background: "#fafafa" }} item={true}>
                        <Grid item xs={12}>
                            {loadingPosts ? <LoadingSpinner></LoadingSpinner> : ''}
                        </Grid>
                        {refPosts.length > 0 && !loadingPosts ? refPosts.map((post, index) => {

                            if (index < numberOfResultsShowing) {
                                return (
                                    <Grid style={{
                                        width: "95%",
                                        margin: "0 auto",
                                        padding: "1% 0 0 0"
                                    }} item={true} key={post.id}>
                                        <Results
                                            number={index + 1}
                                            date={post.acf.year}
                                            title={post.title.rendered}
                                            authors={post.acf.authors}
                                            year={post.acf.year.slice(0, 4)}
                                            source={post.acf.source}
                                            linkTo={post.id}
                                            forest={post.acf.forest_type}
                                            species={post.acf.species_type}
                                            climate={post.acf.climate_zone}
                                            aspect={post.acf.aspect}
                                        ></Results>
                                    </Grid>)
                            }


                        }) : ''}
                        {refPosts.length === 0 && !loadingPosts ? <Grid style={{ margin: "2.5% 5%" }}><h1 style={{}}>No results found!</h1></Grid> : ''}
                    </Grid>

                    <Grid container>
                        <Grid item xs={12} sx={{
                            marginTop: "2em",
                            marginBottom: "3em" 
                        }}>
                            {
                                refPosts.length > 0 && numberOfResultsShowing < refPosts.length ? <Button variant="outlined" onClick={incrementResultCount} color="success">Load more results</Button> :''
                            }
                        </Grid>
                    </Grid>


                    {/* Pagination 
                {refPosts.length > 0 && refPosts.length >= resultsPerPage ? <Container style={{
                    paddingTop: "",
                    paddingBottom: "1%",
                    margin: "0 auto",
                    display: "flex",
                    justifyContent: "center"
                }}><Pagination ref={paginationRef} showScrollToTop={showScrollToTopButton} currentPage={setCurrentPageHandler} postsPerPage={resultsPerPage} totalPosts={refPosts.length}></Pagination></Container> : ''}*/}

                </div>
                {showScrollToTopButton ? <button id='scrollToTopButton' onClick={scrollTopTop}>&uarr;</button> : ''}
            </Container>
        </React.Fragment>
    )
}

export default Filter