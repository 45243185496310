// import { useState } from "react"
import React from "react";

// MuiKit
import { Fab, Grid, Stack } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { memo } from "react";

const theme = createTheme({
    palette: {
        forestCol: {
            main: "#00e676",
            dark: "#4caf50",
            contrastText: "#000",
        },
        speciesCol: {
            main: "#ffe082",
            dark: "#ffca28",
            contrastText: "#000",
        },
        climateCol: {
            main: "#448aff",
            dark: "#039be5",
            contrastText: "#fff",
        },
        aspectCol: {
            main: "#ff5252",
            dark: "#ba000d",
            contrastText: "#fff",
        },
    },
});

const Results = memo((props) => {
    return (
        <ThemeProvider theme={theme}>
            <Card sx={{ marginBottom: "2%", padding: "1%" }}>
                <CardContent variant="outlined">
                    <Stack direction="column" alignItems="flex-start">
                        {/* <h6>Published: {props.date}</h6> */}
                        <h2 style={{ lineHeight: 1.3, margin: "0", textAlign: "left" }}>{props.title}</h2>
                    </Stack>
                    <Stack direction="column" alignItems="flex-start">
                        <Grid
                            direction="row"
                            alignItems="center"
                            wrap="wrap"
                            container={true}
                        >
                            <h3 style={{ lineHeight: 1.8, margin: "20px 0", textAlign: "left" }}>
                                <b>Author</b>: &nbsp;
                            </h3>
                            <p>{props.authors}</p>
                        </Grid >
                        {/* <p style={{ lineHeight: 1.3, margin: "0 0 20px 0", textAlign: "left" }}>
                            <b>Year</b>: {props.year}
                        </p> */}
                        <p style={{ lineHeight: 1.3, margin: "0", textAlign: "left" }}>
                            <b>Source</b>: {props.title}
                        </p>
                    </Stack>

                    <Grid
                        direction="row"
                        alignItems="center"
                        wrap="wrap"
                        spacing={2}
                        container={true}
                        margin="dense"
                        style={{ marginTop: "10px" }}
                    >
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            wrap="wrap"
                            spacing={2}
                            style={{ marginTop: "10px" }}
                        >
                            {props.forest &&
                                props.forest.map((ft, i) => {
                                    if (i === 0) {
                                        return (
                                            <Fab
                                                variant="extended"
                                                size="small"
                                                color="forestCol"
                                                aria-label="add"
                                                style={{ marginLeft: "10px", marginRight: "10px" }}
                                                key={ft}
                                            >
                                                {ft}
                                            </Fab>
                                        );
                                    } else {
                                        return (
                                            <Fab
                                                variant="extended"
                                                size="small"
                                                color="forestCol"
                                                aria-label="add"
                                                style={{ marginLeft: "0", marginRight: "10px" }}
                                                key={ft}
                                            >
                                                {ft}
                                            </Fab>
                                        );
                                    }
                                })}
                        </Stack>

                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            style={{ marginTop: "10px" }}
                        >
                            {props.species &&
                                props.species.map((ft) => {
                                    return (
                                        <Fab
                                            variant="extended"
                                            size="small"
                                            color="speciesCol"
                                            style={{ marginRight: "10px" }}
                                            aria-label="add"
                                            key={ft}
                                        >
                                            {ft}
                                        </Fab>
                                    );
                                })}
                        </Stack>

                        <Stack
                            direction="row"
                            wrap="wrap"
                            justifyContent="space-between"
                            alignItems="center"
                            style={{ marginTop: "10px" }}
                        >
                            {props.climate &&
                                props.climate.map((ft) => {
                                    return (
                                        <Fab
                                            variant="extended"
                                            size="small"
                                            color="climateCol"
                                            style={{ marginRight: "10px" }}
                                            aria-label="add"
                                            key={ft}
                                        >
                                            {ft}
                                        </Fab>
                                    );
                                })}
                        </Stack>

                        <Stack
                            direction="row"
                            wrap="wrap"
                            justifyContent="space-between"
                            alignItems="center"
                            style={{ marginTop: "10px" }}
                        >
                            {props.aspect &&
                                props.aspect.map((ft) => {
                                    return (
                                        <Fab
                                            variant="extended"
                                            size="small"
                                            color="aspectCol"
                                            style={{ marginRight: "10px" }}
                                            aria-label="add"
                                            key={ft}
                                        >
                                            {ft}
                                        </Fab>
                                    );
                                })}
                        </Stack>
                    </Grid>
                </CardContent>
            </Card>
        </ThemeProvider>
    );
});

export default Results;
