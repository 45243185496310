import React, { useState, memo } from 'react';
import DropdownSelectUIK from '../DropdownSelect-uik';

import { fetchPost } from '../fetch-post';
import { postAPI, token } from '../../constants/consts';
import { useEffect } from 'react';
import { useRef } from 'react';
import Timeline from './Timeline';

// MuiKit
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Divider, IconButton } from '@mui/material';

// icon
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import CloseIcon from '@mui/icons-material/Close';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import { Grid } from '@mui/material';

const Search = memo((props) => {

    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [keyword, setKeyword] = useState('');

    const [startYear, setStartYear] = useState(null);
    const [endYear, setEndYear] = useState(null);
    const [authors, setAuthors] = useState([]);
    const [selectedAuthor, setSelectedAuthor] = useState([]);

    const [forestType, setForestType] = useState([]);
    const [speciesType, setSpeciesType] = useState([]);
    const [climateType, setClimateType] = useState([]);
    const [aspect, setAspect] = useState([]);

    const [showAdvanceFilter, setShowAdvanceFilter] = useState(false);

    const [yearRange, setYearRange] = useState([])

    const textInputValueRef = useRef();
    const submitButtonRef = useRef();

    const forestDropdownRef = useRef();
    const speciesDropdownRef = useRef();
    const climateDropdownRef = useRef();
    const aspectDropdownRef = useRef();

    // Getting results as props from Filter.jsx
    const propRefPosts = props.propRefPosts;

    // Search query object
    let searchQuery = {
        keyword: keyword,
        startYear: startYear,
        endYear: endYear,
        authors: selectedAuthor,
        forest_type: forestType,
        species_type: speciesType,
        climate_zone: climateType,
        aspect: aspect,
    }

    const refSearchQuery = {
        keyword: '',
        startYear: [],
        endYear: null,
        authors: null,
        forest_type: [],
        species_type: [],
        climate_zone: [],
        aspect: [],
    }

    // Fetching post once when the component loads  
    // setting refPosts: calling this once to avoid re-rendering of author list
    useEffect(() => {
        fetchPostHandler(postAPI); // we are extracting authors and years as a part of this.
    }, [])

    // Getting the author data for dropdowns
    const extractAuthors = (data) => {
        let authors_ = {};

        data.forEach(result => {
            const { authors } = result.acf;
            const id = result.id;

            if (!authors_[id]) {
                authors_[authors] = [id]
            } else {
                authors_[authors].push(id)
            }

        })

        setAuthors(authors_)
    }

    // Extract all years
    const extractYearsFromResults = (data) => {

        let year_data = [];

        data.forEach(result => {
            const { year } = result.acf;
            year_data.push(year)

        })

        if (year_data.length === 0) {
            return []
        }

        if (year_data.length === 1) {
            return [year_data[0]]
        }

        let earliestLatestDates = {
            "earliest": year_data[0],
            "lastest": year_data[1]
        }

        for (var i = 2; i < year_data.length; i++) {
            if (year_data[i] < earliestLatestDates.earliest) {
                earliestLatestDates.earliest = year_data[i]
            }

            if (year_data[i] > earliestLatestDates.lastest) {
                earliestLatestDates.lastest = year_data[i]
            }
        }

        setYearRange([Number(earliestLatestDates.earliest), Number(earliestLatestDates.lastest)])
    }

    // Used with use effect - line 76
    const fetchPostHandler = (api) => {

        props.loading(true);
        fetchPost(api, (res) => {

            // Passing data to parent
            props.fetchingForParent(res.data);
            extractAuthors(res.data);
            extractYearsFromResults(res.data)
            props.loading(false);


        }, (err) => {
            console.error(err);
        });
    }

    // Building query object on change of dropdown elements
    const searchQueryBuilder = (type, selections) => {

        switch (type) {
            case "keyword":
                setKeyword(selections)
                break;
            case "authors":
                setSelectedAuthor(selections)
                break;
            case "forest_type":
                setForestType(selections)
                break;
            case "species_type":
                setSpeciesType(selections)
                break;
            case "climate_zone":
                setClimateType(selections)
                break;
            case "aspect":
                setAspect(selections)
                break;
            case "startYear":
                setStartYear(selections)
                break;
            case "endYear":
                setEndYear(selections)
                break;
            default:
                return false;
        }

        if (keyword.length > 0) {
            setButtonDisabled(false)
        } else {
            setButtonDisabled(true)
        }

        searchQuery[type] = selections;         // query
        // console.log("searchQuery", searchQuery);
    }

    // Search button press
    const submitFilterSearchQueryHandler = async (e) => {
        e.preventDefault();

        if (textInputValueRef.current.value) {
            setButtonDisabled(false)
        } else {
            setButtonDisabled(true)
        }

        props.filterSearchTerm(searchQuery);

        clearFilterHandler()
    }

    // Advance-filter toggle
    const showAdvanceFilterToggleHandler = () => {
        setShowAdvanceFilter(prevState => !prevState)
    }

    // Clear filter handler
    const clearFilterHandler = () => {

        props.resetPosts(true);

        const checkboxes = document.querySelectorAll('input[type="checkbox"]');
        const selectionCount = document.querySelectorAll('.selection-count');
        const dropdownElement = document.querySelectorAll(".dropdown-content");

        searchQuery = refSearchQuery;

        // start and end year
        const startEndYearCheckboxes = document.querySelectorAll('select[data-year*="year"]');
        startEndYearCheckboxes.forEach(yearList => {
            yearList.value = "";
        });

        checkboxes.forEach(cb => {
            cb.checked = false;
        })

        selectionCount.forEach(SC => {
            SC.innerHTML = "0";
        })

        dropdownElement.forEach(dd => {
            dd.classList.remove("d-flex", "flex-column")
            dd.classList.add("d-none")
        })


        /*
        // de-select dropdown
        
        let selectElement = document.querySelectorAll(".search-container select:selected");
        for (let i = 0; i < selectElement.length; i++) {
            selectElement[i].selected = false;
        }
        */
        // fetchPostHandler(postAPI) -> no need to fetch the posts again, just tell the parent to reset the refPosts;

        // setAuthors([]) => causing the authors to be empty
        setSelectedAuthor([])
        setForestType([])
        setSpeciesType([])
        setClimateType([])
        setAspect([])
        setStartYear(null)
        setEndYear(null)

        forestDropdownRef.current = null;
        speciesDropdownRef.current = null;
        climateDropdownRef.current = null;
        aspectDropdownRef.current = null;

        setShowAdvanceFilter(false);
        setButtonDisabled(true);

        props.resetPosts(false);

        textInputValueRef.current.value = null;

        // console.log(searchQuery);
    }

    return (
        <Grid style={{ width: "95%", margin: "0 auto" }}>
            <Grid item={true}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                // margin
                padding
            >

                <Grid container
                    direction="row"
                    justifyContent="flex-start" item={true} xs={!showAdvanceFilter ? 9 : 12} md={!showAdvanceFilter ? 10 : 12} lg={!showAdvanceFilter ? 10 : 12}>
                    <TextField
                        // label="With normal TextField"
                        id="search-publications"
                        label="Search Publications..."
                        spellCheck="true"
                        inputRef={textInputValueRef}
                        sx={{ width: '100%' }}
                        // InputProps={{
                        //     startAdornment: <InputAdornment position="start"> <SearchIcon color="contrastText" /></InputAdornment>,
                        // }}
                        onKeyUp={(e) => searchQueryBuilder("keyword", textInputValueRef.current.value)}
                        autoComplete="off"
                    />
                </Grid>


                {!showAdvanceFilter ?
                    <Grid container
                        direction="row"
                        justifyContent="center" item={true} xs={1} md={1}>
                        <IconButton type="submit" id="side-search" variant="contained" disabled={buttonDisabled} ref={submitButtonRef} onClick={submitFilterSearchQueryHandler}><SearchIcon color="contrastText" /></IconButton>
                    </Grid> : ''}

                {!showAdvanceFilter ?
                    <Grid container
                        direction="row"
                        justifyContent="center" item={true} xs={1} md={1}>
                        {/* <IconButton id="side-clean-filter" disabled={buttonDisabled} onClick={clearTextFieldHandler} ><CleaningServicesIcon /></IconButton> */}
                        <IconButton id="side-clean-filter" onClick={clearFilterHandler} ><CleaningServicesIcon /></IconButton>
                    </Grid> : ''}
            </Grid>

            <Divider
                sx={{ paddingTop: "2%", paddingBottom: "2%", }}>
                <IconButton id="expand-filter" onClick={showAdvanceFilterToggleHandler} variant="contained">
                    {!showAdvanceFilter ? <TuneIcon /> : <CloseIcon />}
                </IconButton>
            </Divider>

            {showAdvanceFilter ? <React.Fragment>

                <Grid item={true}
                    container
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ paddingBottom: "2%" }}
                >

                    <Grid item={true}
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                    >

                        <Timeline startYear={yearRange[0]} endYear={yearRange[1]} queryYear={searchQueryBuilder}></Timeline>

                        <Grid container item={true} xs={12} md={4}>

                            <FormControl fullWidth={true} sx={{ m: 0.5 }}>
                                <InputLabel id="author-dropdown-label">Author</InputLabel>
                                <Select
                                    labelId="author-dropdown-label"
                                    id="author-dropdown"
                                    label="Author"
                                    value={selectedAuthor}
                                    onChange={(e) => searchQueryBuilder("authors", e.target.value)}
                                >
                                    {authors && Object.entries(authors).map(([key, val], i) => {
                                        return <MenuItem key={i} value={key}>{key}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>

                    </Grid>

                    <Grid item={true}
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        style={{
                            paddingTop: "2%"
                        }}
                    >

                        <Grid spacing={0} container item={true} xs={6} md={3}>
                            <DropdownSelectUIK
                                ref={forestDropdownRef}
                                dropdowns={["Planted", "Natural"]}
                                title="Forest Type"
                                dataType="forest_type"
                                parentDropdownDataHandler={searchQueryBuilder} />

                        </Grid>

                        <Grid spacing={0} container item={true} xs={6} md={3}>
                            <DropdownSelectUIK
                                ref={speciesDropdownRef}
                                dropdowns={["Softwood",
                                    "Hardwood"
                                ]} title="Species type"
                                dataType="species_type"
                                parentDropdownDataHandler={searchQueryBuilder} />
                        </Grid>

                        <Grid spacing={0} container item={true} xs={6} md={3}>
                            <DropdownSelectUIK
                                ref={climateDropdownRef}
                                dropdowns={["Equatorial", "Tropical", "Sub-tropical", "Temperate", "Grasslands", "Desert"
                                ]} title="Climatic zone"
                                dataType="climate_zone"
                                parentDropdownDataHandler={searchQueryBuilder} />
                        </Grid>

                        <Grid spacing={0} container item={true} xs={6} md={3}>
                            <DropdownSelectUIK
                                ref={aspectDropdownRef}
                                dropdowns={["Establishment", "Maintenance", "Harvet & haul", "Processing", "Markets", "Health", "Investment", "Environmental services"
                                ]} title="Aspect"
                                dataType="aspect"
                                parentDropdownDataHandler={searchQueryBuilder} />
                        </Grid>

                        <Grid spacing={0} container item={true}>

                        </Grid>

                    </Grid>

                    <Grid item={true}
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        padding
                    >
                        <Button style={{ padding: "10px" }} color="error" variant="outlined" onClick={clearFilterHandler}>Clear filter <CleaningServicesIcon style={{ marginLeft: "10px" }} /></Button>

                        <Button style={{ padding: "10px" }} color="success" variant="outlined" onClick={submitFilterSearchQueryHandler}>Search <SearchIcon style={{ marginLeft: "10px" }} /></Button>
                    </Grid>
                </Grid>
            </React.Fragment> : ''}


        </Grid>

    )
})

export default Search;