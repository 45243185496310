import axios from "axios";
import { token } from "../constants/consts";

const axiosInstance = axios.create({
    headers: {
        'auth-key': token,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET',
        'Access-Control-Allow-Headers': 'auth-key'
    }
});


export const fetchPost = (url, config, callback, errorcallback) => {

    axiosInstance.get(url)
        .then(res => {
            //do something
            if (callback != null) {
                callback(res);
            }
        })
        .catch(err => {
            // catch error
            if (errorcallback != null) {
                errorcallback(err);
            }
        })
}